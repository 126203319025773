
import {
  Component,
  Inject,
  InjectReactive,
  Vue,
  Watch,
} from "vue-property-decorator";
import { IS_MOBILE_SYMBOL, USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";
import { SupportDialogEntity } from "@/entities/support-dialog.entity";
import gql from "graphql-tag";
import { getElementScrollHeight, getElementScrollPosition } from "@/helpers";
import anime from "animejs";
import { Config } from "vuescroll";

@Component({
  components: {},
  apollo: {
    popularQuestions: {
      update(data) {
        return data.popularSupportDialogQuestions;
      },
      skip() {
        return !this.user;
      },

      query: gql`
        query {
          popularSupportDialogQuestions
        }
      `,
    },
    dialog: {
      update(data) {
        return data.supportDialog;
      },
      skip() {
        return !this.user;
      },

      query: gql`
        query {
          supportDialog {
            _id
            messages {
              _id
              text
              intent
              suggestions
              byUser
            }
          }
        }
      `,
      subscribeToMore: [
        {
          updateQuery(previousResult, { subscriptionData }) {
            const key = Object.keys(previousResult)[0];
            if (!previousResult[key] || !this.dialog) {
              return;
            }

            const item =
              subscriptionData.data.subscribeSupportDialogMessageAdded;

            return {
              [key]: {
                ...previousResult[key],
                messages: [...previousResult[key].messages, item],
              },
            };
          },
          document: gql`
            subscription {
              subscribeSupportDialogMessageAdded {
                _id
                text
                intent
                byUser
                suggestions
              }
            }
          `,
        },
      ],
    },
  },
})
export default class SupportDialog extends Vue {
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  public text = "";
  public sending = false;
  public minMessageHeight = 86;
  public showDialogRate = false;
  public showDialogRateDelay: any = null;
  public dialog: SupportDialogEntity | null = null;
  public oldDialogs: SupportDialogEntity[] = [];
  public scrollAnimation: any | null = null;
  public popularQuestions: string[] = [];
  private scrollOptions: Config = {
    rail: {
      keepShow: false,
    },
    bar: {
      disable: true,
    },
    vuescroll: {
      mode: "slide",
      zooming: false,
    },
    scrollPanel: {
      scrollingX: true,
      scrollingY: false,
    },
  };

  get messages(): SupportDialogEntity["messages"] {
    return [
      {
        byUser: false,
        suggestions: this.popularQuestions,
        text: `Здравствуйте, чем я могу Вам помочь?`,
        _id: "1",
      },
      ...this.oldDialogs.map(({ messages }) => messages).flat(),
      ...((this.dialog || {}).messages || []),
    ];
  }

  async rateSupportDialog() {
    if (!this.dialog) {
      return;
    }

    this.sending = true;
    await this.$apollo.mutate({
      mutation: gql`
        mutation ($dialogId: ID!, $solved: Boolean!) {
          rateSupportDialog(dialogId: $dialogId, solved: $solved)
        }
      `,
      variables: {
        dialogId: this.dialog._id,
        solved: true,
      },
    });

    const oldDialog = this.dialog;
    this.oldDialogs.push(oldDialog);
    this.dialog = null;

    this.sending = false;
    clearTimeout(this.showDialogRateDelay);
    this.showDialogRate = false;
  }

  async send(text?: string) {
    if (this.sending || (!this.text && !text)) {
      return;
    }

    this.sending = true;

    await this.$apollo
      .mutate({
        mutation: gql`
          mutation ($text: String!) {
            createSupportDialogMessage(text: $text) {
              _id
            }
          }
        `,
        variables: {
          text: text ?? this.text,
        },
      })
      .catch(console.error);

    if (!this.dialog) {
      await this.$apollo.queries.dialog.refetch();
    }

    this.sending = false;

    if (!text) {
      this.text = "";
      (this.$refs.input as any).focus();
    }
  }

  @Watch("dialog")
  async dialogWatcher(newVal, oldVal) {
    if (
      ((newVal && !oldVal) ||
        (oldVal &&
          newVal &&
          oldVal.messages.length < newVal.messages.length)) &&
      newVal.messages[newVal.messages.length - 1].intent !== "None" &&
      !newVal.messages[newVal.messages.length - 1].byUser
    ) {
      clearTimeout(this.showDialogRateDelay);
      this.showDialogRateDelay = setTimeout(() => {
        this.showDialogRate = true;
      }, 3000);
    }
  }

  @Watch("dialog.messages.length")
  async messagesWatcher(newLength, oldLength) {
    await this.$nextTick();
    const scrollPositionFromBottom =
      getElementScrollHeight("#support-dialog-scroll") -
      getElementScrollPosition("#support-dialog-scroll");
    if (
      scrollPositionFromBottom <= this.minMessageHeight ||
      this.scrollAnimation
    ) {
      await this.scrollToBottom(200);
    }
  }

  mounted() {
    if (this.dialog) {
      this.scrollToBottom();
    } else {
      const unwatch = this.$watch("$apollo.queries.dialog.loading", (value) => {
        if (!value) {
          this.scrollToBottom();
          unwatch();
        }
      });
    }
  }

  async scrollToBottom(ms = 0, delay = 0) {
    await new Promise((resolve) => setTimeout(resolve, delay));
    if (this.$refs.scroll) {
      if (ms === 0 || !document.hasFocus()) {
        await (this.$refs.scroll as any).scrollToBottom();
      } else {
        if (this.scrollAnimation) {
          await this.scrollAnimation.finished;
        }

        this.scrollAnimation = anime({
          targets: "#support-dialog-scroll",
          scrollTop: getElementScrollHeight("#support-dialog-scroll"),
          duration: ms,
          easing: "linear",
          complete: () => {
            this.scrollAnimation = null;
          },
        });
      }
    }
  }
}
