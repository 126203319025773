
  import {Component, Inject, Vue, Watch} from "vue-property-decorator";
import SupportDialog from "@/components/support-dialog/SupportDialog.vue";
import { IS_MOBILE_SYMBOL } from "@/constants";

@Component({
  components: {
    SupportDialog,
  },
})
export default class SupportDialogBtn extends Vue {
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  public show = false;

  @Watch('show')
  showWatcher(value){
    if(!value && this.$route.hash){
      this.$router.replace(this.$route.path);
    }
  }

  click(){
    this.show = !this.show
  }
}
